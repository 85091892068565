import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

function ParagraphsSection() {
    const { t } = useTranslation();

    const paragraphs = [
        "This rebrand marks a significant step in our journey to empower gig workers and SMEs with innovative fintech solutions, reflecting our mission to help individuals drive, earn, and own their success.",
        "Born from a passion to support gig workers, Thrivve is more than a name change. It's a commitment to transforming lives. By connecting with top mobility apps, Thrivve offers flexible vehicle leasing options that pave a clear path to ownership. With our instant earning cashout feature, gig workers and SMEs can access their money immediately, eliminating payment cycle delays and enhancing financial flexibility. Imagine the freedom to seize work opportunities, boost revenues, and access working capital faster, all through Thrivve.",
        "As a Saudi-based startup, Thrivve embodies a vision to create an impactful business that champions financial inclusion and growth. Join us on this transformative journey and witness how Thrivve is shaping the future of the gig economy.",
        "Welcome to Thrivve, where potential meets endless possibilities.",
        "Drive. Earn. Own.",
        "Let's Thrivve!"
    ];
    function Paragraph({ text, key }: { text: string, key: string }) {
        return (
            t(`${text}`)
                ? <Col span={24} key={key}>
                    <p className="paragraph">{t(`${text}`)}</p>
                </Col>
                : null
        );
    }
    return (
        <Row className="paragraphs-section">
            {
                paragraphs.filter((paragraph) => paragraph).map((itm: string, index: number) => (
                    <Paragraph text={itm} key={`paragraph${index}`} />
                ))
            }
        </Row>
    );
}

export default ParagraphsSection;