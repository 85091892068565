import React from "react";
import { Col, Divider, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "../onboarding.style.css";

function FAQSection() {
    const { t } = useTranslation();
    const faqItems = [
        {
            question: t("How much can I withdraw?"),
            answer: t(`You can withdraw multiples of 50 SAR, with a minimum amount of 50 SAR.`)
        },
        {
            question: t("Is there a fee for each payment I receive?"),
            answer: t("Yes, there is a fee of 5 SAR per transaction.")
        },
        {
            question: t("When will I receive my money?"),
            answer: t("It takes 1 2 working days. You will be notified once your request is approved.")
        },
        {
            question: t("How will I be paid?"),
            answer: t("The money will be sent to your bank account.")
        },
        {
            question: t("Can I add a new bank account?"),
            answer: t("Yes, you can add up to 5 bank accounts.")
        },
        {
            question: t("Will I be able to send money to my bank account immediately after adding it?"),
            answer: t(`No, it takes 1 3 working days for the new bank account to be reviewed. Once accepted, you can withdraw your money to that account. You will be notified once your request is approved.`)
        },
        {
            question: t("What should I do if I have any issue, suggestion, or question?"),
            answer: t("Feel free to reach out to us via WhatsApp"),
            link: "https://wa.me/+966576030983"
        }
    ];
    function getFAQItem(data: any) {
        return (
            <Row justify={"center"} key={`faq${data?.answer}`} className="onboarding-questionItem">
                <Col span={24} style={{ marginBottom: "0.5rem" }}>
                    <Typography.Text strong className="textCenterAlign questionTitle">
                        {data?.question}
                    </Typography.Text>
                </Col>
                <Col span={24} className="centerBlockContent">
                    {
                        data?.link
                            ? <a href={data?.link} target="_blank" rel="noreferrer" >
                                <Typography.Text className="textCenterAlign questionAnswer">
                                    {data?.answer}
                                </Typography.Text>
                            </a>
                            : <Typography.Text className="textCenterAlign questionAnswer">
                                {data?.answer}
                            </Typography.Text>
                    }
                </Col>
                <Col span={8}>
                    <Divider type={"horizontal"} className="questionDivider" />
                </Col>
            </Row>
        )
    }
    return (
        <Row
            justify={"center"} align={"top"} className="onboarding-faqSection"
        >
            <Col span={24}>
                <Typography.Title level={1} className="textCenterAlign title"> {t("FAQs")} </Typography.Title>
            </Col>
            <Col span={24}>
                {
                    faqItems?.map((itm: any) => (
                        getFAQItem(itm)
                    ))
                }
            </Col>
        </Row>
    );
}
export default FAQSection;