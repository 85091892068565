const CoinsIcon = ({ color = "#FF9800", ...props }) => {
    return (
        <svg {...props} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1271_295)">
                <path
                    d="M43.9649 8.23196C52.6744 13.9079 58.4444 23.7328 58.4444 34.8813C58.4444 46.0298 52.6744 55.8545 43.9649 61.5307C58.3776 61.1988 70.0002 49.3722 70.0002 34.8813C70.0002 20.3904 58.3776 8.56381 43.9649 8.23196Z"
                    fill={color}
                />
                <path
                    d="M26.6573 8.22427C11.9585 8.22427 0 20.1826 0 34.8814C0 49.5802 11.9583 61.5385 26.6571 61.5385C41.3559 61.5385 53.3144 49.5802 53.3144 34.8814C53.3144 20.1826 41.3561 8.22427 26.6573 8.22427ZM29.2223 51.0065L29.2223 52.4493C29.2223 53.8659 28.0739 55.0145 26.6571 55.0145C25.2403 55.0145 24.0919 53.8661 24.0919 52.4493L24.0919 51.0029C21.909 50.3925 19.9901 49.0176 18.7067 47.069C17.9275 45.8859 18.255 44.2948 19.4381 43.5159C20.6214 42.7367 22.2122 43.0641 22.9912 44.2473C23.8082 45.4878 25.1808 46.2284 26.6627 46.2284C29.0837 46.2284 31.0534 44.2586 31.0534 41.8375C31.0534 39.4165 29.0838 37.4468 26.6627 37.4468C26.6524 37.4468 26.6421 37.4468 26.6316 37.4466C21.3906 37.4363 17.1301 33.169 17.1301 27.9255C17.1301 23.5622 20.0813 19.8777 24.0918 18.7567V17.3138C24.0921 15.8971 25.2405 14.7485 26.6571 14.7485C28.0737 14.7485 29.2223 15.8969 29.2223 17.3137V18.7601C31.4054 19.3704 33.3245 20.7454 34.6077 22.6942C35.3867 23.8773 35.0591 25.4683 33.876 26.2473C32.6927 27.0259 31.1018 26.6987 30.3229 25.5155C29.5063 24.275 28.1339 23.5346 26.6515 23.5346C24.2306 23.5346 22.2607 25.5044 22.2607 27.9255C22.2607 30.3466 24.2304 32.3164 26.6515 32.3164C26.6619 32.3164 26.6722 32.3164 26.6827 32.3165C31.9237 32.3269 36.184 36.594 36.184 41.8375C36.184 46.2009 33.2328 49.8855 29.2223 51.0065Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_1271_295">
                    <rect width="70" height="70" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CoinsIcon;
