// Initial State
const initialState: { [k: string]: any } = {};

// Reducer
const HomeReducer = (state = initialState, action: any) => {
    let newState = state;
    // switch (action.type) {
    // }
    return newState;
};
export default HomeReducer;
