import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "./assets/locales/ar.json";
import en from "./assets/locales/en.json";
import { ACTIVE_SYSTEM_LANGUAGES, LANGUAGES } from "./shared/enums";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: { ar, en },
        fallbackLng: LANGUAGES.ENGLISH,
        debug: process.env.REACT_APP_DEBUG_TRANSLATION_KEYS,
        locales: ACTIVE_SYSTEM_LANGUAGES,
        load: ACTIVE_SYSTEM_LANGUAGES,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            convertDetectedLanguage: (lng: string) => {
                lng = lng.toLowerCase().replace("-", "_");
                const parts = lng.split("_");
                const systemLang = parts[0] || LANGUAGES.ENGLISH;
                if (ACTIVE_SYSTEM_LANGUAGES.includes(systemLang)) {
                    return systemLang;
                }
                return LANGUAGES.ARABIC;
            },
        },
    } as any);

export default i18n;
