import { motion } from "framer-motion";

export const FadeUpFramer = ({ children, ...props }: any) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: "1.75rem" }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: "easeInOut", duration: 0.85 }}
            viewport={{ margin: "0% 0px -12.5% 0px" }}
            {...props}
        >
            {children}
        </motion.div>
    );
};
