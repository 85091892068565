const LightBulbIcon = ({ color = "#451A6B", ...props }) => {
    return (
        <svg {...props} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <g id="Layer_2_00000174577694339328093070000010159420606202412691_">
                    <path
                        id="Vector"
                        d="M22.8232 61.8605H47.1767C46.7542 66.4718 42.8865 70.0009 38.2558 70H31.7442C27.1135 70.0007 23.2458 66.4718 22.8232 61.8605Z"
                        fill={color}
                    />
                    <path
                        id="Vector_2"
                        d="M56.9767 35C56.9925 41.6674 53.9582 47.9759 48.7395 52.1256C47.7715 52.8875 47.2073 54.0519 47.2093 55.2837V56.9768L22.7907 56.9768V55.2837C22.7618 54.0263 22.1776 52.8461 21.1953 52.0605C15.0411 47.1296 12.0181 39.2675 13.2837 31.4837C15.2377 19.4974 26.5387 11.3645 38.525 13.3185C49.1555 15.0515 56.966 24.2293 56.9767 35Z"
                        fill={color}
                    />
                    <path
                        id="Vector_3"
                        d="M35 11.3953C33.6515 11.3953 32.5582 10.3021 32.5582 8.95349V2.44186C32.5582 1.09329 33.6515 0 35 0C36.3486 0 37.4419 1.09329 37.4419 2.44186V8.95349C37.4419 10.3021 36.3486 11.3953 35 11.3953Z"
                        fill={color}
                    />
                    <path
                        id="Vector_4"
                        d="M53.4182 19.0237C52.0696 19.0224 50.9773 17.9283 50.9784 16.5797C50.979 15.9334 51.2358 15.3136 51.6926 14.8562L56.2963 10.2558C57.3018 9.35698 58.8455 9.44343 59.7443 10.4489C60.5728 11.3757 60.5728 12.7769 59.7443 13.7037L55.1471 18.3107C54.6883 18.7686 54.0662 19.0251 53.4182 19.0237Z"
                        fill={color}
                    />
                    <path
                        id="Vector_5"
                        d="M67.5582 37.4418H61.0465C59.698 37.4418 58.6047 36.3485 58.6047 35C58.6047 33.6514 59.698 32.5581 61.0465 32.5581H67.5582C68.9067 32.5581 70 33.6514 70 35C70 36.3485 68.9067 37.4418 67.5582 37.4418Z"
                        fill={color}
                    />
                    <path
                        id="Vector_6"
                        d="M16.5818 19.0237C15.9347 19.025 15.3136 18.7684 14.8562 18.3106L10.2558 13.7037C9.35698 12.6983 9.44343 11.1546 10.4489 10.2558C11.3757 9.42727 12.7769 9.42727 13.7037 10.2558L18.3107 14.8562C19.2638 15.8104 19.2628 17.3565 18.3086 18.3095C17.8503 18.7671 17.2293 19.024 16.5818 19.0237Z"
                        fill={color}
                    />
                    <path
                        id="Vector_7"
                        d="M8.95349 37.4418H2.44186C1.09329 37.4418 0 36.3485 0 35C0 33.6514 1.09329 32.5581 2.44186 32.5581H8.95349C10.3021 32.5581 11.3953 33.6514 11.3953 35C11.3953 36.3485 10.3021 37.4418 8.95349 37.4418Z"
                        fill={color}
                    />
                </g>
            </g>
        </svg>
    );
};

export default LightBulbIcon;
