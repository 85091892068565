import React from "react";
import { Col, Image, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import CelebrationCorn from "../../../../assets/imgs/CelebrationCorn.jpg";
import "../onboarding.style.css";

function CongratsSection() {
    const { t } = useTranslation();
    return (
        <Row justify={"center"} align={"top"} className="onboarding-congratsSection">
            <Col>
                <Typography.Title level={2} className="title">
                    {t("Congratulation!")}
                </Typography.Title>
            </Col>
            <Col span={24} className="centerBlockContent">
                <Image
                    src={CelebrationCorn} alt={"celebrate image"} preview={false}
                    className="onboarding-celebrateImage"
                />
            </Col>
            <Col className="congratsSecondContainer">
                <span className="textCenterAlign text" >
                    {t("You have been chosen to Get Your Money Any time")}
                </span>
            </Col>
            <Col span={24} className="congratsThirdContainer">
                <Typography.Paragraph className="textCenterAlign secondaryText">
                    {t(`Starting now, you can view your daily earnings and withdraw your earnings at any time. Here’s how to see your earnings and start withdrawing your money.`)}
                </Typography.Paragraph>
            </Col>
        </Row>
    );
}
export default CongratsSection;