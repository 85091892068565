import { useMemo, useState } from "react";
import {
    Link,
    // useNavigate
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Col, Drawer, Menu, Row, Space, Typography } from "antd";
import "./nav-bar-section.style.scss";
import ThrivveLogo from "../../../assets/imgs/thrivve-logo.svg";
import ThrivveLogoAr from "../../../assets/imgs/thrivve-logo-ar.svg";
import ThrivveLogoWhite from "../../../assets/imgs/thrivve-logo-white.svg";
import ThrivveLogoArWhite from "../../../assets/imgs/thrivve-logo-ar-white.svg";
import { ChevronDownIcon, GlobeIcon, MenuIcon } from "../../../assets/icons";
import LanguageSelect from "../language-select/language-select.ui";

const NavBarSection = ({ isWhite = false }) => {
    const [drawerState, setDrawerState] = useState(false);
    // const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const openDrawer = () => {
        setDrawerState(true);
    };
    const onCloseDrawer = () => {
        setDrawerState(false);
    };

    const navMenuItems = (isMobile = false) => [
        {
            key: "0",
            label: (
                <>
                    {t("footerCompany")} {isMobile ? <></> : <ChevronDownIcon className={isWhite ? "white-arrow" : ""} />}
                </>
            ),
            children: [
                { key: "0_0", label: t("aboutUs") },
                { key: "0_1", label: t("press") },
                { key: "0_2", label: t("careers") },
                { key: "0_3", label: t("missionAndVision") },
                { key: "0_4", label: t("productRoadmap") },
                { key: "0_5", label: t("helpCenter") },
                { key: "0_6", label: t("contactUs") },
            ],
        },
        {
            key: "1",
            label: (
                <>
                    {t("footerForBusinesses")} {isMobile ? <></> : <ChevronDownIcon className={isWhite ? "white-arrow" : ""} />}
                </>
            ),
            children: [
                { key: "1_0", label: t("vehicleLeasing") },
                { key: "1_1", label: t("instantPayment") },
                { key: "1_2", label: t("workWithUber") },
            ],
        },
        {
            key: "2",
            label: (
                <>
                    {t("forGigWorkers")} {isMobile ? <></> : <ChevronDownIcon className={isWhite ? "white-arrow" : ""} />}
                </>
            ),
            children: [
                // { key: "2_0", label: t("workforceManagementPlatform") },
                { key: "2_1", label: t("vehicleLeasing") },
                // { key: "2_2", label: t("vehicleManagementPlatform") },
                { key: "2_3", label: t("instantPaymentSolutions") },
                { key: "2_4", label: t("accessToMoreWorkOpportunities") },
            ],
        },
        // {
        //     key: "3",
        //     label: mobileChangeLang()
        // }
    ];
    function mobileChangeLang() {
        return (
            <Row align={"middle"} justify={"space-between"} className="mobile-drawer-lang-selector">
                <Col>
                    <Space direction="horizontal" align={"center"}>
                        <Avatar size={22} src={<GlobeIcon />} shape="circle" />
                        <Typography.Text>{t("language.base")}</Typography.Text>
                    </Space>
                </Col>
                <Col>
                    <Space direction="horizontal" align={"center"}>
                        <Button
                            className={`mobile-lang-btn ${i18n?.dir() === "rtl" ? "active-btn" : ""}`}
                            onClick={(e: any) => onLangBtnClick(e, "ar")}
                        >العربية</Button>
                        <Button
                            className={`mobile-lang-btn ${i18n?.dir() === "ltr" ? "active-btn" : ""}`}
                            onClick={(e: any) => onLangBtnClick(e, "en")}
                        >English</Button>
                    </Space>
                </Col>
            </Row>
        )
    }
    function onLangBtnClick(event: any, lang: string) {
        event.stopPropagation()
        {
            const langCheck: any = {
                en: "en",
                ar: "ar"
            }
            if (!langCheck[lang]) {
                return
            }
        }
        i18n.changeLanguage(lang);
        onCloseDrawer();
    }
    const onMenuItemClick = (data: any) => {
        const dataKeyToSection: any = {
            "1_0": ".business-offers-section",
            "1_1": ".business-offers-section",
            "1_2": ".business-offers-section",
            "2_1": ".individual-offers-section",
            "2_3": ".individual-offers-section",
            "2_4": ".individual-offers-section"
        }
        if (dataKeyToSection?.[data?.key]) {
            const itemSection: HTMLElement | null = document.querySelector(dataKeyToSection?.[data?.key]);
            itemSection?.scrollIntoView({ block: "start", behavior: "smooth" });
            return
        } else {
            return
        }
    };

    const NavMenu = ({ inline = false }) => {
        return (
            <Menu
                className="nav-list hide-in-mobile"
                mode={inline ? "inline" : "horizontal"}
                forceSubMenuRender={true}
                selectable={false}
                items={navMenuItems(inline)}
                onClick={onMenuItemClick}
            />
        );
    };

    const websiteLogo = useMemo(() => {
        if (i18n.language === "en") {
            return isWhite ? ThrivveLogoWhite : ThrivveLogo;
        } else {
            return isWhite ? ThrivveLogoArWhite : ThrivveLogoAr;
        }
    }, [isWhite, i18n.language]);

    function goToDownloadSection() {
        const downloadSection: HTMLElement | null = document.querySelector(".download-app-section");
        downloadSection?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    return (
        <div className="screen-wrapper">
            <nav className={`${isWhite ? "white" : "black"}`}>
                <Link className="logo" to="#">
                    <img src={websiteLogo} alt="Thrivve" />
                </Link>
                <NavMenu />
                <div className="spacer"></div>
                <LanguageSelect className="hide-in-mobile bold" isWhite={isWhite} />
                <Link className="hide-in-mobile sign-up-btn" onClick={goToDownloadSection} to="#">
                    {t("signUp")}
                </Link>
                <Link className="hide-in-mobile login-btn" onClick={goToDownloadSection} to="#">
                    {t("login")}
                </Link>
                <MenuIcon className="show-in-mobile menu-btn" onClick={openDrawer} />
            </nav>
            <Drawer
                className="main-menu-drawer"
                title={t("mainMenu")}
                placement={i18n.dir() === "rtl" ? "left" : "right"}
                onClose={onCloseDrawer}
                open={drawerState}
            >
                <NavMenu inline />
                {
                    mobileChangeLang()
                }
            </Drawer>
        </div>
    );
};

export default NavBarSection;
