const CarIcon = ({ color = "#451A6B", ...props }) => {
    return (
        <svg {...props} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="car-svgrepo-com 1">
                <path
                    id="Vector"
                    d="M65.625 30.625C65.625 21.5458 60.1387 4.375 39.375 4.375L30.625 4.375C9.86125 4.375 4.375 21.5458 4.375 30.625C1.95672 30.625 0 32.5817 0 35L0 52.5C0 54.9183 1.95672 56.875 4.375 56.875H8.75V61.25C8.75 63.6683 10.7067 65.625 13.125 65.625H17.5C19.9183 65.625 21.875 63.6683 21.875 61.25V56.875L48.125 56.875V61.25C48.125 63.6683 50.0817 65.625 52.5 65.625H56.875C59.2933 65.625 61.25 63.6683 61.25 61.25V56.875H65.625C68.0433 56.875 70 54.9183 70 52.5V35C70 32.5817 68.0433 30.625 65.625 30.625ZM17.5 48.125C15.0817 48.125 13.125 46.1683 13.125 43.75C13.125 41.3317 15.0817 39.375 17.5 39.375C19.9183 39.375 21.875 41.3317 21.875 43.75C21.875 46.1683 19.9183 48.125 17.5 48.125ZM13.125 30.625C13.125 29.9119 13.3263 13.125 30.625 13.125L39.375 13.125C56.2297 13.125 56.8575 28.852 56.875 30.625L13.125 30.625ZM52.5 48.125C50.0817 48.125 48.125 46.1683 48.125 43.75C48.125 41.3317 50.0817 39.375 52.5 39.375C54.9183 39.375 56.875 41.3317 56.875 43.75C56.875 46.1683 54.9183 48.125 52.5 48.125Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default CarIcon;
