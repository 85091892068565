import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "../../../assets/icons";
import CarouselManImg from "../../../assets/imgs/carousel-man.png";
import { FadeUpFramer } from "../../../shared/components/custom-reveal-animations/custom-reveal-animations.ui";

const CarouselCardsSection = () => {
    const {
        t,
        i18n: { dir, language },
    } = useTranslation();
    const [disablePrevious, setDisablePrevious] = useState(true);
    const [disableNext, setDisableNext] = useState(false);
    const listRef = useRef(null);
    const itemsRef = useRef([] as HTMLElement[]);

    useEffect(() => {
        const list = listRef.current as HTMLElement | null;
        if (list && language) {
            list.scrollTo({ left: 0 });
            handleOnScroll();
        }
    }, [listRef, language]);

    const handleOnScroll = () => {
        const list = (listRef as any).current;
        const scrollLeft = Math.abs(list.scrollLeft);
        const maxScrollLeft = list.scrollWidth - list.clientWidth;
        if (scrollLeft < 20) {
            setDisablePrevious(true);
            setDisableNext(false);
        } else if (maxScrollLeft - scrollLeft < 20) {
            setDisablePrevious(false);
            setDisableNext(true);
        } else {
            setDisablePrevious(false);
            setDisableNext(false);
        }
    };

    const handleClick = (direction: string) => {
        const list = listRef.current as HTMLElement | any | null;
        let itemWidth = ((itemsRef.current[0] || {}).offsetWidth ?? 0) * 0.985;
        if (dir() === "rtl") {
            itemWidth *= -1;
        }
        if (list && itemWidth) {
            if (direction === "previous") {
                list.scrollBy({ left: -itemWidth, behavior: "smooth" });
            } else {
                list.scrollBy({ left: itemWidth, behavior: "smooth" });
            }
        }
    };

    const carouselCards = [
        <div
            className="carousel-card"
            style={{
                backgroundImage: `url(${CarouselManImg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "100% 100%"
            }}
        >
            <div className="sub-card">
                <p className="sub-title">
                    {t("Thrivve has made my life so much easier. It helped me lease a vehicle and get my first job with Uber.")}
                </p>
                {/* <button type="button" className="watch-btn">
                    Watch Video
                </button> */}
            </div>
        </div>,
        <div className="carousel-card">
            <p className="title">
                {t("“I use Thrivve to manage my earnings and cash them out anytime. It's so convenient not having to wait until the end of the month.”")}
            </p>
            {/* <p className="review-btn">Feralad on Trustpilot</p> */}
        </div>,
        <div className="carousel-card" style={{ backgroundColor: `var(--secondary-color-lighten)` }}>
            <p className="title">
                {t("“I use Thrivve to earn more money. It helps me get onboarded with several ride-hailing apps at the same time, maximizing my opportunities.”")}
            </p>
            {/* <p className="review-btn">Feralad on Trustpilot</p> */}
        </div>,
        <div className="carousel-card" style={{ backgroundColor: `var(--primary-color)` }}>
            <p className="title">
                {t("“Thrivve has really simplified managing my earnings. I can cash out my earnings anytime without having to wait until the end of the month.”")}
            </p>
            {/* <p className="review-btn">Feralad on Trustpilot</p> */}
        </div>,
        // <div className="carousel-card">
        //     <p className="title">
        //         “I use Thrivve to pay a mortgage in a different country each month. Superb. That simple.”
        //     </p>
        //     <p className="review-btn">Feralad on Trustpilot</p>
        // </div>,
    ];

    return (
        <section className="carousel-cards-section">
            <div className="screen-wrapper">
                <div className="title-wrapper">
                    <FadeUpFramer>
                        <h3>{t("hearFromGigs")}</h3>
                        <div className="button-wrapper">
                            <button
                                className="carousel-btn"
                                type="button"
                                disabled={disablePrevious}
                                onClick={() => handleClick("previous")}
                            >
                                <ArrowNarrowLeftIcon
                                    style={{ opacity: disablePrevious ? 0.25 : 1 }}
                                    color={disablePrevious ? undefined : "#fff"}
                                />
                            </button>
                            <button
                                className="carousel-btn"
                                type="button"
                                disabled={disableNext}
                                onClick={() => handleClick("next")}
                            >
                                <ArrowNarrowRightIcon
                                    style={{ opacity: disableNext ? 0.25 : 1 }}
                                    color={disableNext ? undefined : "#fff"}
                                />
                            </button>
                        </div>
                    </FadeUpFramer>
                </div>
                <div className="card-wrapper-spacer"></div>
            </div>
            <div className="floating-img-wrapper">
                <div className="cards-wrapper">
                    <FadeUpFramer>
                        <div className="list-wrapper">
                            <ul ref={listRef} className="list" onScroll={handleOnScroll}>
                                {carouselCards.map((item, index) => (
                                    <li
                                        key={index}
                                        ref={(el) => (itemsRef.current[index] = el as HTMLElement)}
                                        className="item"
                                    >
                                        {/* NOTE: The reason one is a div and the other is span, 
                                         is because it forces a redraw of the virtual DOM for this animation nodes / elements,
                                         if this did not occur the animation breaks after switching a language once */}
                                        {dir() === "rtl" ? (
                                            <motion.div
                                                initial={{ opacity: 0, scale: 0.33 }}
                                                whileInView={{ opacity: 1, scale: 1 }}
                                                transition={{ ease: "easeIn", duration: 0.15 }}
                                                viewport={{
                                                    root: listRef,
                                                    margin: "0px 0px 0px 93%",
                                                    amount: 0.9,
                                                }}
                                            >
                                                {item}
                                            </motion.div>
                                        ) : (
                                            <motion.p
                                                style={{ display: "block", margin: 0 }}
                                                initial={{ opacity: 0, scale: 0.33 }}
                                                whileInView={{ opacity: 1, scale: 1 }}
                                                transition={{ ease: "easeIn", duration: 0.15 }}
                                                viewport={{
                                                    root: listRef,
                                                    margin: "0px 93% 0px 0px",
                                                    amount: 0.9,
                                                }}
                                            >
                                                {item}
                                            </motion.p>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </FadeUpFramer>
                    <FadeUpFramer viewport={{ margin: "0px 0px 0px 0px" }}>
                        <div className="mobile-btn-wrap button-wrapper">
                            <button
                                className="carousel-btn show-in-mobile"
                                type="button"
                                disabled={disablePrevious}
                                onClick={() => handleClick("previous")}
                            >
                                <ArrowNarrowLeftIcon
                                    style={{ opacity: disablePrevious ? 0.25 : 1 }}
                                    color={disablePrevious ? undefined : "#fff"}
                                />
                            </button>
                            <button
                                className="carousel-btn show-in-mobile"
                                type="button"
                                disabled={disableNext}
                                onClick={() => handleClick("next")}
                            >
                                <ArrowNarrowRightIcon
                                    style={{ opacity: disableNext ? 0.25 : 1 }}
                                    color={disableNext ? undefined : "#fff"}
                                />
                            </button>
                        </div>
                    </FadeUpFramer>
                </div>
            </div>
        </section>
    );
};

export default CarouselCardsSection;
