const ROUTES_ENUM = {
    BASE: "/",
    NOT_FOUND: "/not-found",
    PUBLIC:{
        APP:"/app",
        ANNOUNCEMENT: "/thrivve-brand-announcement",
        ANNOUNCEMENT_AR: "/إعلان-العلامة-التجارية-ثرايف"
    },
    GET_STARTED:"get-started"
};

export default ROUTES_ENUM;
