/**
 * Implements the path.join function from the node path library without the need to import it.
 * @param parts string array of paths to join
 * @param sep the separator to join by or use
 * @returns Successfully join path.
 */
export const pathJoin = (...parts: string[]) => {
    const separator = "/";
    const replace = new RegExp(separator + "{1,}", "g");
    return parts.join(separator).replace(replace, separator);
};
