const ArrowNarrowLeftIcon = ({ color = "black", ...props }) => {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-narrow-left">
                <path
                    id="Icon"
                    d="M33.3334 20H6.66669M6.66669 20L16.6667 30M6.66669 20L16.6667 10"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default ArrowNarrowLeftIcon;
