const MenuIcon = ({ color = "white", ...props }) => {
    return (
        <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.06775 7.84131L20.6511 7.84131" stroke={color} strokeLinecap="round" />
            <path d="M6.06775 13.0498L20.6511 13.0498" stroke={color} strokeLinecap="round" />
            <path d="M6.06775 18.2583L20.6511 18.2583" stroke={color} strokeLinecap="round" />
        </svg>
    );
};

export default MenuIcon;
