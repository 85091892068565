function Clock({ color = "rgba(0, 0, 0, 0.4)", ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill={"none"}
            viewBox="0 0 48 48"
            {...props}
        >
            <circle
                cx="24"
                cy="24"
                r="18.399"
                stroke={color}
                strokeOpacity="0.4"
                strokeWidth="4.798"
            ></circle>
            <path
                stroke={color}
                strokeLinecap="round"
                strokeOpacity="0.4"
                strokeWidth="4.798"
                d="M33 24h-7.8a1.2 1.2 0 01-1.2-1.2V17"
            ></path>
        </svg>
    );
}

export default Clock;
