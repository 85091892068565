import Step1Img from "../../../assets/imgs/step-1.png";
import Step2Img from "../../../assets/imgs/step-2.png";
import Step3Img from "../../../assets/imgs/step-3.png";
import Step4Img from "../../../assets/imgs/step-4.png";
import { useTranslation } from "react-i18next";
import { FadeUpFramer } from "../../../shared/components/custom-reveal-animations/custom-reveal-animations.ui";

const StepsToVehicles = () => {
    const { t, i18n } = useTranslation();
    const steps = [
        { image: Step1Img, text: "signUpToThrivve" },
        { image: Step2Img, text: "getVerified" },
        { image: Step3Img, text: "chooseVehicleStep" },
        { image: Step4Img, text: "driveAndOwn" },
    ];

    return (
        <section className="steps-to-vehicles">
            <div className="screen-wrapper">
                <div className="title-wrapper">
                    <FadeUpFramer>
                        <h3>
                            <span>{t("Four steps")}</span>
                            <span className="colored">{t("to vehicle")}</span>
                            {i18n.dir() === "ltr"
                                ? <span className="colored hide-ar">{t("ownership")}</span>
                                : null
                            }
                            {/* <span className="colored">{t("toVehicleOwnership")}</span> */}
                        </h3>
                        <p className="subtitle hide-in-mobile">{t("stepsDetails")}</p>
                    </FadeUpFramer>
                </div>
                <div className="steps-wrapper">
                    {steps.map((step, index) => (
                        <FadeUpFramer key={index}>
                            <div className="step">
                                <img src={step.image} alt={t("illustration")} />
                                <p className="text">{t(step.text)}</p>
                            </div>
                        </FadeUpFramer>
                    ))}
                </div>
                <FadeUpFramer>
                    <button className="get-started-btn show-in-mobile">{t("getStarted")}</button>
                </FadeUpFramer>
            </div>
        </section>
    );
};

export default StepsToVehicles;
