// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    CarIcon,
    // GroupPeopleIcon,
    InstantPaymentIcon,
    LightBulbIcon,
    // SettingsGearIcon,
    // TinyArrowLeftIcon,
} from "../../../assets/icons";
import SectionBg1 from "../../../assets/imgs/section-bg-1.svg";
import SectionBg2 from "../../../assets/imgs/section-bg-2.svg";
import SectionBg6 from "../../../assets/imgs/section-bg-6.svg";
// import SectionBg7 from "../../../assets/imgs/section-bg-7.svg";
import SectionBg8 from "../../../assets/imgs/section-bg-8.svg";
import { FadeUpFramer } from "../../../shared/components/custom-reveal-animations/custom-reveal-animations.ui";

const BusinessOffersSection = () => {
    const { t } = useTranslation();

    const cardsList = [
        {
            icon: <CarIcon />,
            title: "vehicleLeasing",
            details: "vehicleLeasingDetails",
            url: "https://wa.me/+966576030983",
            sectionImg: <img className="hide-sm section-bg" src={SectionBg6} alt="Section Background" />,
        },
        {
            icon: <InstantPaymentIcon />,
            title: "instantPaymentSolutions",
            details: "instantPaymentSolutionsDetails",
            url: "https://wa.me/+966576030983",
        },
        // {
        //     icon: <GroupPeopleIcon />,
        //     title: "workforceManagementPlatform",
        //     details: "workforceManagementPlatformDetails",
        //     url: "#",
        //     sectionImg: <img className="section-bg" src={SectionBg7} alt="Section Background" />,
        // },
        // {
        //     icon: <SettingsGearIcon />,
        //     title: "vehicleManagementPlatform",
        //     details: "vehicleManagementPlatformDetails",
        //     url: "#",
        // },
        {
            icon: <LightBulbIcon />,
            title: "accessToMoreWorkOpportunities",
            details: "accessToMoreWorkOpportunitiesDetails",
            url: "https://wa.me/+966576030983",
            sectionImg: <img className="hide-sm section-bg" src={SectionBg8} alt="Section Background" />,
        },
    ];
    return (
        <section className="business-offers-section">
            <div className="screen-wrapper">
                <div className="title-wrapper">
                    <FadeUpFramer>
                        <h3 className="title">{t("whatOfferBusiness")}</h3>
                        <p className="details">{t("whatOfferBusinessDetails")}</p>
                    </FadeUpFramer>
                </div>
                <ul className="cards-wrapper">
                    {cardsList.map((card, index) => (
                        <li key={index}>
                            <FadeUpFramer>
                                <div className="card">
                                    {card.icon}
                                    <h5 className="title">{t(card.title)}</h5>
                                    <p className="details">{t(card.details)}</p>
                                    {/* <Link className="explore-btn" to={card.url}>
                                        {t("exploreMore")} <TinyArrowLeftIcon />
                                    </Link> */}
                                    {card.sectionImg ? card.sectionImg : <></>}
                                </div>
                            </FadeUpFramer>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="floating-img-wrapper">
                <img className="section-bg-1" src={SectionBg1} alt="Section Background" />
                <img className="section-bg-2" src={SectionBg2} alt="Section Background" />
            </div>
        </section>
    );
};

export default BusinessOffersSection;
