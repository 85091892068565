import { Suspense } from "react";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import router from "../routes/config";
import configureStore from "../configure-store";

const store = configureStore();

function App() {
    return (
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "var(--primary-color)",
                        fontFamily: "var(--font-family)",
                    },
                }}
            >
                <Suspense fallback="loading...">
                    <RouterProvider router={router} />
                </Suspense>
            </ConfigProvider>
        </Provider>
    );
}

export default App;
