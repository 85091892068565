import React from "react";
import { Button, Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "../onboarding.style.css";

function HelpSection() {
    const { t } = useTranslation();
    function onContactUsClick() {
        window.open("https://wa.me/+966576030983", '_blank');
    }

    return (
        <Row
            justify={"center"} align={"top"} className="onboarding-helpSection"
            gutter={[0, 30]}
        >
            <Col span={24} className="centerBlockContent">
                <Typography.Title className="textCenterAlign title" level={2}>{t("Still Need Support?")}</Typography.Title>
                <Typography.Text className="textCenterAlign subTitle">
                    {t(`We re here to help! If you have any more questions or need further assistance, just click the button below.`)}
                </Typography.Text>
            </Col>
            <Col lg={6} md={8} sm={24} xs={24}>
                <Button
                    block size="large" className="onboarding-contactUsButton"
                    onClick={onContactUsClick}
                >
                    {t("Contact Us")}
                </Button>
            </Col>
        </Row>
    );
}
export default HelpSection;