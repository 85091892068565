import NotFound from "../../ui/not-found/not-found.ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Outlet,
    useLocation,
    useNavigate, useParams
} from "react-router-dom";
import { pathJoin } from "../../shared/utils/general.utils";
import { ACTIVE_SYSTEM_LANGUAGES, LANGUAGES } from "../../shared/enums";

const ACTIVE_URL_LANGUAGES = ACTIVE_SYSTEM_LANGUAGES.filter((lang) => lang !== LANGUAGES.ENGLISH);

const MainGuard = () => {
    const {
        t,
        i18n: { language, dir, changeLanguage },
    } = useTranslation();
    const [firstTime, setFirstTime] = useState(true);
    const { lang } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const handleLanguage = useCallback(function handleUrlLanguage() {
        const languagePrefix = language === LANGUAGES.ENGLISH ? "" : `${language}`;
        let navPath = "";
        let replace = false;
        if (firstTime) {
            if (!lang && language !== LANGUAGES.ENGLISH) {
                changeLanguage(LANGUAGES.ARABIC);
            } else if (lang && ACTIVE_URL_LANGUAGES.includes(lang)) {
                changeLanguage(lang);
            }
            setFirstTime(false);
        } else {
            if (lang) {
                if (ACTIVE_URL_LANGUAGES.includes(lang) && lang !== language) {
                    const pathParts = location.pathname.split(/[\\/]+/).filter((part) => !!part);
                    navPath = pathJoin("/", languagePrefix, "/", ...pathParts.slice(1));
                    replace = true;
                }
            } else if (languagePrefix) {
                navPath = pathJoin("/", languagePrefix, location.pathname);
                replace = true;
            }
        }
        if (navPath) {
            navigate(navPath, { replace });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, lang, navigate, changeLanguage]);
    // This effects handles adding lang param to the url
    useEffect(() => {
        handleLanguage();
    }, [handleLanguage]);

    const direction = dir();
    document.body.dir = direction;
    document.documentElement.lang = language;
    document.title = `${t("thrivve")} - ${t("empowerTitle")}`;

    return <>{lang && !ACTIVE_URL_LANGUAGES.includes(lang) ? <NotFound /> : <Outlet />} </>;
};

export default MainGuard;
