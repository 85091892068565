import { useTranslation } from "react-i18next";
import CompanyLogo1 from "../../../assets/imgs/sponsor-logo-amazon.png";
import CompanyLogo2 from "../../../assets/imgs/sponsor-logo-hungerstation.png";
import CompanyLogo3 from "../../../assets/imgs/to-you-logo.svg";
import CompanyLogo4 from "../../../assets/imgs/sponsor-logo-noon.png";
import CompanyLogo5 from "../../../assets/imgs/sponsor-logo-uber.png";
// import CompanyLogo6 from "../../../assets/imgs/company-logo-6.png";
// import CompanyLogo7 from "../../../assets/imgs/company-logo-7.png";
// import CompanyLogo8 from "../../../assets/imgs/company-logo-8.png";
import { FadeUpFramer } from "../../../shared/components/custom-reveal-animations/custom-reveal-animations.ui";
// import { Col, Row } from "antd";

const SponsorsIconsSection = () => {
    const { t } = useTranslation();
    const sponsors = [
        { image: CompanyLogo1, alt: "amazon", className: "withoutBorderInMobile" },
        { image: CompanyLogo2, alt: "hugerstation" },
        { image: CompanyLogo3, alt: "mrsool", className: "withoutBorderInMobile" },
        { image: CompanyLogo4, alt: "noon" },
        { image: CompanyLogo5, alt: "uber", className: "withoutBorderInMobile" },
        // { image: CompanyLogo6, alt: "whatsapp" },
        // { image: CompanyLogo7, alt: "bmw" },
        // { image: CompanyLogo8, alt: "marriott" },
    ];
    return (
        <section className="sponsors-icons-section">
            <FadeUpFramer viewport={{ margin: "0% 0% 0% 0%" }}>
                <p className="trusted">{t("trustedByPartners")}</p>
            </FadeUpFramer>
            <div className="screen-wrapper">
                {sponsors.map((sponsor, index) => (
                    <div className="sponsor-container">
                        <FadeUpFramer key={index} viewport={{ margin: "0% 0% 0% 0%" }}>
                            <div className={`sponsor ${sponsor?.className}`}>
                                <img src={sponsor.image} alt={sponsor.alt} />
                            </div>
                        </FadeUpFramer>
                    </div>
                ))}
            </div>
            {/* <Row>
                {sponsors.map((sponsor, index) => (
                    <Col xl={4} lg={4} className="sponsor">
                        <FadeUpFramer key={index} viewport={{ margin: "0% 0% 0% 0%" }}>
                            <img src={sponsor.image} alt={sponsor.alt} />
                        </FadeUpFramer>
                    </Col>
                ))}
            </Row> */}
        </section>
    );
};

export default SponsorsIconsSection;
