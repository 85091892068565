import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Accent from "../../../assets/imgs/Vehicle-Accent.png";
import Elentra from "../../../assets/imgs/Vehicle-Hyudai-Elantra.png";
import MgGt from "../../../assets/imgs/Vehicle-MG-GT.png";
import MgRx from "../../../assets/imgs/Vehicle-MG-RX.png";
import { FadeUpFramer } from "../../../shared/components/custom-reveal-animations/custom-reveal-animations.ui";
import { TinyArrowLeftIcon } from "../../../assets/icons";
import CarPdf from "../../../assets/files/Cars.pdf";

const ChooseVehiclesSection = () => {
    const { t, i18n } = useTranslation();

    const cars = [
        {
            image: Elentra,
            name: "HYUNDAI ELANTRA ",
            year: "2024",
            fuelType: "Petrol",
            colors: "2 Colours",
            price: "2,299",
            period: "month",
            currency: "SAR",
        },
        {
            image: Accent,
            name: "HYUNDAI ACCENT",
            year: "2024",
            fuelType: "Petrol",
            colors: "3 Colours",
            price: "1,999",
            period: "month",
            currency: "SAR",
        },
        {
            image: MgGt,
            name: "MG GT",
            year: "2023",
            fuelType: "Hybrid",
            colors: "2 Colours",
            price: "2,199",
            period: "month",
            currency: "SAR",
        },
        {
            image: MgRx,
            name: "MG RX",
            year: "2023",
            fuelType: "Petrol",
            colors: "3 Colours",
            price: "2,149",
            period: "month",
            currency: "SAR",
        },
    ];
    const carsAr = [
        {
            image: Accent,
            name: "هونداي أكسنت",
            year: "٢٠٢٤",
            fuelType: "بنزين",
            colors: "٣ ألوان",
            price: "١,٩٩٩",
            period: "بالشهر",
            currency: "ر.س",
        },
        {
            image: Elentra,
            name: "هونداي إلترا",
            year: "٢٠٢٤",
            fuelType: "بنزين",
            colors: "لونان",
            price: "٢,٢٩٩",
            period: "بالشهر",
            currency: "ر.س",
        },
        {
            image: MgRx,
            name: "أم جي - أر أكس",
            year: "٢٠٢٣",
            fuelType: "بنزين",
            colors: "٣ ألوان",
            price: "٢,١٤٩",
            period: "بالشهر",
            currency: "ر.س",
        },
        {
            image: MgGt,
            name: "أم جي - جي تي",
            year: "٢٠٢٣",
            fuelType: "هايبرد",
            colors: "لونان",
            price: "٢,١٩٩",
            period: "بالشهر",
            currency: "ر.س",
        }
    ];
    function getCarsBasedOnLanguage() {
        if (i18n.dir() === "ltr") {
            return cars;
        } else {
            return carsAr;
        }
    }
    return (
        <section className="choose-vehicles-section">
            <FadeUpFramer>
                <div className="screen-wrapper title-wrapper">
                    <h3 className="title">{t("chooseYourVehicle")}</h3>
                    <Link className="explore-btn" to={CarPdf} target="_blank">
                        {t("viewAll")} <TinyArrowLeftIcon />
                    </Link>
                </div>
            </FadeUpFramer>
            <div className="screen-wrapper card-wrapper">
                {getCarsBasedOnLanguage().map((car, index) => (
                    <FadeUpFramer key={index}>
                        <div className="card">
                            <img className="preview" src={car.image} alt={t("carPreview")} />
                            <p className="name">{car.name}</p>
                            <p className="details">
                                <span className="year">{car.year}.</span>
                                <span className="fuel-type">{car.fuelType}.</span>
                                <span className="colors">{car.colors}.</span>
                            </p>
                            <p className="price">
                                <span className="value">
                                    {car.currency} {car.price}
                                </span>
                                <span className="period"> / {car.period}</span>
                            </p>
                        </div>
                    </FadeUpFramer>
                ))}
                <FadeUpFramer className="show-in-mobile">
                    <Link className="view-all-btn" to={CarPdf} target="_blank">
                        {t("viewAllCars")}
                    </Link>
                </FadeUpFramer>
            </div>
        </section>
    );
};

export default ChooseVehiclesSection;
