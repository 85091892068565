import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./root-reducer";

export type AppState = ReturnType<typeof rootReducer>;

export default function configureAppStore(preloadedState?: any) {
    const store = configureStore({
        reducer: rootReducer,
        preloadedState,
    });
    // hot reload if reducers changed
    /*if (process.env.NODE_ENV !== 'production' && (module as any).hot) {

        (module as any).hot.accept('./ui', () => store.replaceReducer(rootReducer))
    }*/
    return store;
}
