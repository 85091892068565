import React from "react";
import { Button, Col, Image, Row } from "antd";
import { useTranslation } from "react-i18next";
import Thrivvelogo from "../../../../assets/imgs/thrivve-logo.svg";
import thrivveLogoAr from "../../../../assets/imgs/thrivve-logo-ar.svg";

import "../onboarding.style.css";
function HeaderSection() {
    const { i18n } = useTranslation();
    function onLanguageClick() {
        const arToEnSwitch: any = {
            ar: "en",
            en: "ar"
        }
        i18n.changeLanguage(arToEnSwitch[i18n?.language] ?? "en");
    }
    return (
        <>
            <header className="onboarding-headerSection">
                <Row align={"middle"} justify={"space-between"}>
                    <Col>
                        <Image
                            className={`onboarding-thriveLogo ${i18n?.dir() !== "ltr" ? "hide" : ""} `}
                            src={Thrivvelogo} alt={"Thrivve logo"} preview={false}
                        />
                        <Image
                            className={`onboarding-thriveLogo ${i18n?.dir() === "ltr" ? "hide" : ""} `}
                            src={thrivveLogoAr} alt={"Thrivve logo"} preview={false}
                        />
                    </Col>
                    <Col>
                        <Button
                            className="onboarding-langButton" onClick={onLanguageClick}
                        >
                            {i18n?.language === "en" ? "العربية" : "English"}
                        </Button>
                    </Col>
                </Row>
            </header>
        </>
    );
}
export default HeaderSection;