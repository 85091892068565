const InstantPaymentIcon = ({ color = "#451A6B", ...props }) => {
    return (
        <svg {...props} width="70" height="66" viewBox="0 0 70 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <g id="Group_2">
                    <g id="Group_3">
                        <path
                            id="Vector"
                            d="M63.3697 17.7381C63.5836 17.7381 63.5836 17.7381 63.7965 17.7381L60.5893 5.33496C59.7337 1.69803 55.8829 -0.440772 52.4609 0.628631L4.98131 13.2476C1.34535 14.1031 -0.793458 17.9529 0.275944 21.375L4.33967 36.7744L4.33967 27.5776C4.33967 22.0186 8.83116 17.5271 14.3921 17.5271L63.3697 17.5271V17.7381Z"
                            fill={color}
                        />
                        <path
                            id="Vector_2"
                            d="M63.3697 21.1602L14.1772 21.1602C10.5413 21.1602 7.54788 24.1555 7.54788 27.7905L7.54788 59.0151C7.54788 62.653 10.5422 65.6454 14.1772 65.6454H63.3697C67.0057 65.6454 69.9991 62.6502 69.9991 59.0151V27.7924C70.001 24.1545 67.0057 21.1602 63.3697 21.1602ZM65.5095 59.018C65.5095 60.3022 64.441 61.1559 63.3716 61.1559L14.1791 61.1559C12.8958 61.1559 12.0413 60.0874 12.0413 59.0171V51.1054L65.7243 51.1054V59.0171L65.5095 59.018ZM65.5095 35.705L12.0394 35.705L12.0394 27.7924C12.0394 26.5082 13.1088 25.6536 14.1772 25.6536L63.3697 25.6536C64.6539 25.6536 65.5076 26.723 65.5076 27.7924L65.5095 35.705Z"
                            fill={color}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default InstantPaymentIcon;
