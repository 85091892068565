const GlobeIcon = ({ color = "#FF9800", ...props }) => {
    return (
        <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="globe (3) 2" clipPath="url(#clip0_1225_160)">
                <g id="Layer 2">
                    <g id="globe">
                        <path
                            id="Vector"
                            d="M22.4736 20.0754C22.7103 18.394 22.8278 16.698 22.8252 15C22.8278 13.302 22.7103 11.606 22.4736 9.92461H29.1211C30.2959 13.2062 30.2959 16.7938 29.1211 20.0754H22.4736ZM15 30C13.5351 30 12.0398 28.4965 10.8931 25.8756C10.3693 24.6785 9.94276 23.3139 9.62342 21.8326H20.3765C20.0572 23.3133 19.6306 24.6779 19.1068 25.8756C17.9601 28.4965 16.4648 30 15 30ZM9.30233 20.0754C8.80858 16.7098 8.80858 13.2902 9.30233 9.92461H20.6976C20.9473 11.6048 21.0711 13.3013 21.068 15C21.0711 16.6987 20.9473 18.3952 20.6976 20.0754H9.30233ZM0.878891 20.0754C-0.295893 16.7938 -0.295893 13.2062 0.878891 9.92461H7.52635C7.05682 13.2919 7.05682 16.7081 7.52635 20.0754H0.878891ZM15 0C16.4648 0 17.9601 1.50293 19.1068 4.12441C19.6306 5.32148 20.0572 6.68613 20.3765 8.16738H9.62342C9.94276 6.68672 10.3693 5.32207 10.8931 4.12441C12.0398 1.50293 13.5351 0 15 0ZM28.3594 8.1668H22.1707C21.8191 6.42656 21.3305 4.82051 20.7176 3.4207C20.2213 2.28516 19.6588 1.32363 19.043 0.549023C21.0398 1.10201 22.8995 2.065 24.5036 3.37658C26.1076 4.68816 27.4208 6.31957 28.3594 8.1668ZM10.957 0.548437C10.343 1.32363 9.78104 2.28457 9.28416 3.41953C8.67127 4.82051 8.18319 6.42656 7.83104 8.16562H1.64061C2.5794 6.31861 3.89265 4.6874 5.49667 3.37594C7.10068 2.06448 8.9603 1.10153 10.957 0.548437ZM1.64061 21.8332H7.82928C8.18084 23.5734 8.66952 25.1795 9.28241 26.5793C9.77928 27.7143 10.3412 28.6752 10.9553 29.4504C8.95878 28.8972 7.09944 27.9343 5.49571 26.6228C3.89199 25.3113 2.57906 23.6801 1.64061 21.8332ZM19.043 29.4504C19.657 28.6752 20.2195 27.7143 20.7158 26.5793C21.3287 25.1783 21.8168 23.5723 22.1689 21.8332H28.3594C27.4208 23.6805 26.1076 25.312 24.5036 26.6237C22.8995 27.9353 21.0398 28.8984 19.043 29.4516V29.4504Z"
                            fill={color}
                        />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1225_160">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default GlobeIcon;
