import { combineReducers } from "redux";
import MainReducer from "./ui/home/home.redux";
import GetStartedReducer from "./ui/get-started/get-started.reducer";

const rootReducer = combineReducers({
    home: MainReducer,
    GetStarted: GetStartedReducer
});

export default rootReducer;
