import React, { useMemo } from "react";
import { Col, Image, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import DownloadViaAPpStore from "../../../../assets/imgs/DownloadViaAppStore.svg";
import DownloadViaPlay from "../../../../assets/imgs/DownloadViaPlay.svg";
import EnterPhoneNumber from "../../../../assets/imgs/EnterPhoneNumber.png";
import EnterOtp from "../../../../assets/imgs/EnterOtp.png";
import SendMony from "../../../../assets/imgs/SendMony.png";
import SetPassCode from "../../../../assets/imgs/SetPassCode.png";
import ShowEarrings from "../../../../assets/imgs/ShowEarrings.png";
import WaitMony from "../../../../assets/imgs/WaitMony.png";
import WithdrawMony from "../../../../assets/imgs/WithdrawMony.png";
// arabic images
import EnterPhoneNumberAr from "../../../../assets/imgs/EnterPhoneNumberAr.png";
import EnterOtpAr from "../../../../assets/imgs/EnterOtpAr.png";
import SendMonyAr from "../../../../assets/imgs/SendMonyAr.png";
import SetPassCodeAr from "../../../../assets/imgs/SetPassCodeAr.png";
import ShowEarringsAr from "../../../../assets/imgs/ShowEarringsAr.png";
import WaitMonyAr from "../../../../assets/imgs/WaitMonyAr.png";
import WithdrawMonyAr from "../../../../assets/imgs/WithdrawMonyAr.png";
import "../onboarding.style.css";

function StepsSection() {
    const { t, i18n } = useTranslation();
    const steps = useMemo(() => [
        {
            title: t("Step 1"),
            subSteps: [
                {
                    title: t("DownLoad the app"),
                    subtitle: null,
                    images: [
                        {
                            src: DownloadViaPlay,
                            alt: "Download via google play",
                            // className: "onboarding-downloadAppImage",
                            link: "https://play.google.com/store/apps/details?id=com.app.thrivve.finance.thrivve"
                        },
                        {
                            src: DownloadViaAPpStore,
                            alt: "Download via app store",
                            link: "https://apps.apple.com/us/app/thrivve-app/id6478132223"
                        }
                    ]
                }
            ],
            className: "onboarding-firstStep"
        },
        {
            title: t("Step 2"),
            subSteps: [
                {
                    title: t("Login"),
                    subtitle: t("Enter your phone number."),
                    images: [
                        {
                            src: i18n?.dir() === "ltr" ? EnterPhoneNumber : EnterPhoneNumberAr,
                            alt: ""
                        }
                    ],
                    className: "onboarding-subStepSection"
                },
                {
                    title: t("Enter OTP Code"),
                    subtitle: t("Please enter your phone number to log in and start using Thrivve products and services."),
                    images: [
                        {
                            src: i18n?.dir() === "ltr" ? EnterOtp : EnterOtpAr,
                            alt: ""
                        }
                    ],
                    className: "onboarding-subStepSection"
                },
                {
                    title: t("Set Passcode"),
                    subtitle: t(`A passcode is a 4-digit code you need to know each time you log in to your account. Do not share your passcode with anyone. You are the only person who should know it. The application will ask you for it each time you need to use the app.`),
                    images: [
                        {
                            src: i18n?.dir() === "ltr" ? SetPassCode : SetPassCodeAr,
                            alt: ""
                        }
                    ]
                }

            ]
        },
        {
            title: t("Step 3"),
            subSteps: [
                {
                    title: t("Show earrings"),
                    subtitle: t(`In the home screen, you will see a list of transactions where each transaction represent an earnings.`),
                    images: [
                        {
                            src: i18n?.dir() === "ltr" ? ShowEarrings : ShowEarringsAr,
                            alt: ""
                        }
                    ]
                },
            ]
        },
        {
            title: t("Step 4"),
            subSteps: [
                {
                    title: t("Send Money"),
                    subtitle: t(`Click over the “send” button to withdraw money from Thrivve to one of your bank accounts.`),
                    images: [
                        {
                            src: i18n?.dir() === "ltr" ? SendMony : SendMonyAr,
                            alt: ""
                        }
                    ],
                    className: "onboarding-subStepSection"
                },
                {
                    title: t("Withdraw Money"),
                    subtitle: t(`Enter the amount (multiples of 50 SAR), choose your bank account and then click over the withdraw’ button.`),
                    images: [
                        {
                            src: i18n?.dir() === "ltr" ? WithdrawMony : WithdrawMonyAr,
                            alt: ""
                        }
                    ],
                    className: "onboarding-subStepSection"
                },
                {
                    title: t("Wait for 1-2 working days"),
                    subtitle: t(`It takes up to two workings days to get your money in your bank account. We will notify you once the payment request is approved.`),
                    images: [
                        {
                            src: i18n?.dir() === "ltr" ? WaitMony : WaitMonyAr,
                            alt: ""
                        }
                    ]
                }

            ]
        }
    ], [t, i18n]);
    function getStepUi(data: any) {
        return (
            <Row
                justify={"center"} align={"top"} key={data?.title}
                className={data?.className ?? "onboarding-stepSection"}
            >
                <Col span={24} className="centerBlockContent">
                    <Typography.Title level={1} className="stepTitle">{data?.title}</Typography.Title>
                </Col>
                {
                    data?.subSteps?.map((subStep: any, index: number) => (
                        <Col
                            span={24}
                            key={`${data?.title}${subStep?.title}${index}`}
                            className={subStep?.className}
                        >
                            <Typography.Text className="stepSubtitle textCenterAlign">
                                {subStep?.title}
                            </Typography.Text>
                            {
                                subStep?.subtitle
                                    ? <Typography.Text className="textCenterAlign subStepDescription">
                                        {subStep?.subtitle}
                                    </Typography.Text>
                                    : null
                            }
                            <Row>
                                {
                                    subStep?.images?.map((image: any, index: number) => (
                                        <Col span={24} className="centerBlockContent subStepImageContainer"
                                            key={`image${data?.title}${subStep?.title}${index}`}
                                        >
                                            {
                                                image?.link
                                                    ? <a href={image?.link} rel="noreferrer" target="_blank">
                                                        <Image
                                                            className={`${image?.className} `}
                                                            src={image?.src} alt={image?.alt} preview={false}
                                                        />
                                                    </a>
                                                    : <Image
                                                        className={`${image?.className} `}
                                                        src={image?.src} alt={image?.alt} preview={false}
                                                    />
                                            }

                                        </Col>
                                    ))
                                }
                            </Row>

                        </Col>
                    ))
                }
            </Row>
        );
    }
    return (
        <>
            {
                steps?.map((step: any) => (
                    getStepUi(step)
                ))
            }
        </>
    );
}
export default StepsSection;