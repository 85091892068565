import ManInCarImg from "../../../assets/imgs/man-in-car.jpeg";
import AppSnapshot2 from "../../../assets/imgs/hero-section-dashboard.jpg";
import AppSnapshot3 from "../../../assets/imgs/hero-section-dashboard-ar.jpg";
import CarsPdf from '../../../assets/files/Cars.pdf';
import { Link } from "react-router-dom";
import { TinyArrowLeftIcon } from "../../../assets/icons";
import { useTranslation } from "react-i18next";
import NavBarSection from "../../../shared/components/nav-bar-section/nav-bar-section.ui";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Col, Row } from "antd";

const HeroHeaderSection = () => {
    const [showHeroImgs, setShowHeroImgs] = useState(false);
    const [heroImgLoaded, setHeroImgLoaded] = useState(false);
    const [AppImgLoaded, setAppImgLoaded] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setShowHeroImgs(heroImgLoaded && AppImgLoaded);
    }, [heroImgLoaded, AppImgLoaded]);

    const getStartedUrl = process.env.REACT_APP_UBER_LEAD_SITE_URL ?? "https:///uber.thrivve.me";

    return (
        <header className="hero-header-section">
            <div className="gradient-div"></div>
            <div className="floating-img-wrapper">
                <img
                    style={{ display: "none" }}
                    src={ManInCarImg}
                    alt="Man In Car"
                    onLoad={() => setHeroImgLoaded(true)}
                />
                <img
                    style={{ display: "none" }}
                    src={i18n.dir() === "ltr" ? AppSnapshot2 : AppSnapshot3}
                    alt="Man In Car"
                    onLoad={() => setAppImgLoaded(true)}
                />
                {showHeroImgs ? (
                    <motion.div
                        className="animation-wrapper"
                        initial={{ x: i18n.dir() === "rtl" ? "-100%" : "100%" }}
                        whileInView={{ x: 0 }}
                        transition={{ ease: "easeInOut", duration: 0.72 }}
                        viewport={{ margin: "0% 0px -15% 0px", once: true }}
                    >
                        <div
                            className="hero-img"
                            style={{
                                background: `url(${ManInCarImg})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPositionX: "center",
                            }}
                        ></div>
                        <div
                            className="mobile-img"
                            style={{
                                background: `url(${i18n.dir() === "ltr" ? AppSnapshot2 : AppSnapshot3})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100% 98%",
                                backgroundPositionX: "center",
                            }}
                        ></div>
                    </motion.div>
                ) : (
                    <></>
                )}
            </div>
            <NavBarSection isWhite={true} />
            <div className="screen-wrapper">
                <div className="main-section">
                    <div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeInOut", duration: 0.85 }}
                            viewport={{ amount: 0.15 }}
                        >
                            <motion.div
                                initial={{ x: i18n.dir() === "rtl" ? "100%" : "-100%" }}
                                whileInView={{ x: 0 }}
                                transition={{ ease: "easeInOut", duration: 0.85 }}
                                viewport={{ once: true }}
                            >
                                <h1 className="title">{t("empowerTitle")}</h1>
                                <p className="text">{t("empowerDetails")}</p>
                                <Row className="control-btn" justify={"start"} align={"middle"} >
                                    <Col lg={0} md={0} flex={"1"}>
                                        <Link to={getStartedUrl}>
                                            <button className="choose-btn">{t("getStarted")}</button>
                                        </Link>
                                    </Col>
                                    <Col className="hide-sm">
                                        <Link to={getStartedUrl}>
                                            <button className="choose-btn">{t("getStarted")}</button>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link className="explore-btn" to={CarsPdf} target="_blank">
                                            {t("exploreCars")} <TinyArrowLeftIcon />
                                        </Link>
                                    </Col>
                                </Row>
                            </motion.div>
                        </motion.div>
                    </div>
                    <div className="hero-spacer"></div>
                </div>
            </div>
        </header>
    );
};

export default HeroHeaderSection;
