import ROUTES_ENUM from "../shared/routes-enum";
import { createBrowserRouter } from "react-router-dom";
import { pathJoin } from "../shared/utils/general.utils";
import MainGuard from "./guard/main-guard";
import HomePage from "../ui/home/home.ui";
import NotFound from "../ui/not-found/not-found.ui";
import OnBoardingPage from "../ui/public/onboarding/onboarding.ui";
// import GetStartedUi from "../ui/get-started/get-started.ui";
import Announcement from "../ui/public/announcement/announcement.ui";

const routes = [
    {
        path: ROUTES_ENUM.BASE,
        element: <HomePage />,
    },
    {
        path: ROUTES_ENUM.PUBLIC.APP,
        element: <OnBoardingPage />,
    },
    {
        path: ROUTES_ENUM.PUBLIC.ANNOUNCEMENT,
        element: <Announcement />,
    },
    {
        path: ROUTES_ENUM.PUBLIC.ANNOUNCEMENT_AR,
        element: <Announcement />,
    },
    // TODO: either remove / replace this deprecated page
    // {
    //     path: ROUTES_ENUM.GET_STARTED,
    //     element: <GetStartedUi />,
    // },
    {
        path: ROUTES_ENUM.NOT_FOUND,
        element: <NotFound />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
];

export const getPrerenderRoutes = () => {
    const prerenderableRoutes = routes.reduce((acc, route) => {
        if (route && !["*", ":"].includes(route.path)) {
            acc.push(route.path);
        }
        return acc;
    }, [] as string[]);
    const customPrerenderRoutes: string[] = [];
    return [...prerenderableRoutes, ...customPrerenderRoutes];
};

export const getRouterRoutes = () => {
    // Adds language specific routes
    const formalizedRoutes = routes.reduce((acc, route) => {
        if (route && route.path) {
            acc.push(route);
            acc.push({
                ...route,
                path: pathJoin("/", ":lang", route.path),
            });
        }
        return acc;
    }, [] as any[]);
    // Adds main wrapper
    return [
        {
            path: "/",
            element: <MainGuard />,
            children: formalizedRoutes,
        },
    ];
};

const router = createBrowserRouter(getRouterRoutes());
export default router;
