const ArrowNarrowRightIcon = ({ color = "black", ...props }) => {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-narrow-right">
                <path
                    id="Icon"
                    d="M6.66669 20H33.3334M33.3334 20L23.3334 10M33.3334 20L23.3334 30"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default ArrowNarrowRightIcon;
