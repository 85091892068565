import "./footer-section.style.scss";
import ThrivveLogo from "../../../assets/imgs/thrivve-logo.svg";
import ThrivveLogoAr from "../../../assets/imgs/thrivve-logo-ar.svg";
import FooterBg1 from "../../../assets/imgs/footer-bg-1.svg";
import FooterBg2 from "../../../assets/imgs/footer-bg-2.svg";
import FooterBg3 from "../../../assets/imgs/footer-bg-3.svg";
import FooterBg4 from "../../../assets/imgs/footer-bg-4.svg";
import LanguageSelect from "../language-select/language-select.ui";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FaceBook from '../../../assets/imgs/facebook.png';
import X from '../../../assets/imgs/x-social-site.png';
import Instagram from '../../../assets/imgs/instagram.png';
import LinkedIn from '../../../assets/imgs/linkedin.png';
// import { FacebookIcon, LinkedinIcon, InstagramIcon, TwitterIcon } from "../../../assets/icons";
import { Avatar, Col, Row } from "antd";
import ROUTES_ENUM from "../../routes-enum";
// import { Col, Row } from "antd";

const FooterSection = () => {
    const [isMobile] = useState<boolean>(() => window.innerWidth < 768);
    const { t, i18n } = useTranslation();
    const linkSections = [
        {
            label: "footerForBusinesses",
            // lgSpan: 10,
            children: [
                // { label: "workforceManagementPlatform", url: "#" },
                { label: "vehicleLeasing", url: ROUTES_ENUM.BASE },
                // { label: "vehicleManagementPlatform", url: "#" },
                { label: "instantPaymentSolutions", url: ROUTES_ENUM.BASE },
                { label: "accessToMoreWorkOpportunities", url: ROUTES_ENUM.BASE },
            ],
        },
        {
            label: "footerCompany",
            // lgSpan: 7,
            children: [
                { label: "aboutUs", url: ROUTES_ENUM.BASE },
                { label: "press", url: ROUTES_ENUM.BASE },
                { label: "careers", url: ROUTES_ENUM.BASE },
                { label: "missionAndVision", url: ROUTES_ENUM.BASE },
                { label: "productRoadmap", url: ROUTES_ENUM.BASE },
                { label: "helpCenter", url: ROUTES_ENUM.BASE },
                { label: "contactUs", url: ROUTES_ENUM.BASE },
            ],
        },
        {
            label: "forGigWorkers",
            // lgSpan: 7,
            children: [
                { label: "vehicleLeasing", url: ROUTES_ENUM.BASE },
                { label: "instantPayment", url: ROUTES_ENUM.BASE },
                { label: "workWithUber", url: ROUTES_ENUM.BASE },
            ],
        }
    ];
    const mobileLinks = [
        {

            id: "firstCOl",
            children: [
                {
                    label: "footerForBusinesses",
                    // lgSpan: 10,
                    children: [
                        // { label: "workforceManagementPlatform", url: ROUTES_ENUM.BASE },
                        { label: "vehicleLeasing", url: ROUTES_ENUM.BASE },
                        // { label: "vehicleManagementPlatform", url: ROUTES_ENUM.BASE },
                        { label: "footerInstantPaymentSolutions", url: ROUTES_ENUM.BASE },
                        { label: "footerAccessToMoreWorkOpportunities", url: ROUTES_ENUM.BASE },
                    ],
                },
                {
                    label: "forGigWorkers",
                    // lgSpan: 7,
                    children: [
                        { label: "vehicleLeasing", url: ROUTES_ENUM.BASE },
                        { label: "instantPayment", url: ROUTES_ENUM.BASE },
                        { label: "footerAccessToMoreWorkOpportunities", url: ROUTES_ENUM.BASE },
                    ],
                }
            ]
        },
        {
            id: "secondCol",
            children: [
                {
                    label: "footerCompany",
                    // lgSpan: 7,
                    children: [
                        { label: "aboutUs", url: ROUTES_ENUM.BASE },
                        { label: "press", url: ROUTES_ENUM.BASE },
                        { label: "careers", url: ROUTES_ENUM.BASE },
                        { label: "missionAndVision", url: ROUTES_ENUM.BASE },
                        { label: "productRoadmap", url: ROUTES_ENUM.BASE },
                        { label: "helpCenter", url: ROUTES_ENUM.BASE },
                        { label: "contactUs", url: ROUTES_ENUM.BASE },
                    ],
                },
            ]
        }

    ];
    const socialBarTextLinks = [
        { label: "cookiePolicy", url: ROUTES_ENUM.BASE },
        { label: "privacyPolicy", url: ROUTES_ENUM.BASE },
    ];

    const socialBarIconLinks = [
        { icon: <Avatar shape={"square"} src={LinkedIn} size={20} aria-label={t("globeIcon")} />, url: "https://www.linkedin.com/company/thrivve-me" },
        { icon: <Avatar shape="circle" size={20} aria-label={t("globeIcon")} src={FaceBook} />, url: "https://www.facebook.com/thrivve.me" },
        { icon: <Avatar src={Instagram} shape={"square"} size={20} aria-label={t("globeIcon")} />, url: "https://www.instagram.com/wedeliverksa/" },
        { icon: <Avatar src={X} shape={"square"} size={20} aria-label={t("globeIcon")} />, url: "https://x.com/ThrivveMe" },
    ];

    const websiteLogo = useMemo(() => {
        if (i18n.language === "en") {
            return ThrivveLogo;
        } else {
            return ThrivveLogoAr;
        }
    }, [i18n.language]);
    return (
        <footer>
            <div className="screen-wrapper">
                <div className="footer-links-wrapper">
                    {/* <Col xl={10} lg={10} md={10} sm={24} xs={24}> */}
                    <div className="logo-div">
                        <img className="footer-logo" src={websiteLogo} alt="Thrivve" />
                        <LanguageSelect className="footer-lang-select" />
                    </div>
                    {/* </Col> */}
                    {/* <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                        <Row> */}
                    {/* <div className="links-container"> */}
                    {!isMobile
                        ? linkSections.map((section, sectionIndex) => (
                            <div key={`section-${sectionIndex}`} className="links-div">
                                <h5>{t(section.label)}</h5>
                                {section.children.map((link, linkIndex) => (
                                    <Link key={`section-${sectionIndex}-link-${linkIndex}`} to={link.url}>
                                        {t(link.label)}
                                    </Link>
                                ))}
                            </div>
                        ))
                        : <Row justify={"space-between"}>
                            {
                                mobileLinks.map((col: any) => (
                                    <Col key={col.id} span={12}>
                                        {
                                            col?.children?.map((section: any, sectionIndex: number) => (
                                                <div key={`section-${sectionIndex}`} className="links-div">
                                                    <h5>{t(section.label)}</h5>
                                                    {section.children.map((link: any, linkIndex: number) => (
                                                        <Link key={`section-${sectionIndex}-link-${linkIndex}`} to={link.url}>
                                                            {t(link.label)}
                                                        </Link>
                                                    ))}
                                                </div>
                                            ))
                                        }
                                    </Col>
                                ))
                            }
                        </Row>
                    }
                    {/* </div> */}
                    {/* </Row>
                    </Col> */}
                </div>
                <div className="social-bar">
                    {socialBarTextLinks.map((link, linkIndex) => (
                        <div className="link-wrapper" key={`social-text-link-${linkIndex}`}>
                            <Link to={link.url}>{t(link.label)}</Link>
                            {linkIndex < socialBarTextLinks.length - 1 ? (
                                <div className="vertical-divider"></div>
                            ) : null}
                        </div>
                    ))}
                    <div className="spacer"></div>
                    <div className="link-wrapper">
                        {socialBarIconLinks.map((link, linkIndex) => (
                            <Link key={`social-icon-link-${linkIndex}`} className="link-icon" to={link.url}>
                                {link.icon}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div className="floating-img-wrapper">
                <img className="footer-bg-1" src={FooterBg1} alt="Footer background" />
                <img className="footer-bg-1" src={FooterBg1} alt="Footer background" />
                <img className="footer-bg-2" src={FooterBg2} alt="Footer background" />
                <img className="footer-bg-3" src={FooterBg3} alt="Footer background" />
                <img className="footer-bg-4" src={FooterBg4} alt="Footer background" />
            </div>
        </footer >
    );
};

export default FooterSection;
