import "./language-select.style.scss";
import { ChevronDownIcon, GlobeIcon } from "../../../assets/icons";
import { ACTIVE_SYSTEM_LANGUAGES } from "../../enums";
import { ConfigProvider, Menu } from "antd";
import { useTranslation } from "react-i18next";

interface LanguageSelectProps {
    className?: string;
    isWhite?: boolean;
}

const LanguageSelect = ({ className, isWhite }: LanguageSelectProps) => {
    const { t, i18n } = useTranslation();


    const menuItems = [
        {
            key: "lang",
            popupClassName: "lang-popup",
            label: (
                <>
                    <GlobeIcon
                        className="select-icon"
                        role="img"
                        aria-label={t("globeIcon")}
                        color={isWhite ? "#fff" : undefined}
                    />
                    {t(`language.${i18n.language}`)} <ChevronDownIcon className="arrow" />
                </>
            ),
            children: ACTIVE_SYSTEM_LANGUAGES.map((lang) => {
                return { key: lang, label: t(`language.${lang}`) };
            }),
        },
    ];

    const changeLanguageHandler = ({ key }: any) => {
        if (i18n.language !== key) {
            i18n.changeLanguage(key);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    };
    return (
        <div className={`lang-select lang-${i18n.language} ${className}`}>
            <ConfigProvider
                theme={{
                    components: {
                        Menu: {
                            dropdownWidth: 65,
                            itemSelectedBg: "rgba(0, 0, 0, 0.10)",
                            itemSelectedColor: "var(--primary-color)",
                        },
                    },
                }}
            >
                <Menu
                    className={`lang-menu ${isWhite ? "white" : ""}`}
                    mode="horizontal"
                    forceSubMenuRender={true}
                    selectedKeys={["lang", i18n.language]}
                    items={menuItems}
                    triggerSubMenuAction="click"
                    onClick={changeLanguageHandler}
                />
            </ConfigProvider>
        </div>
    );
};

export default LanguageSelect;
