import React from "react";
import { Avatar, Col, Image, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Clock } from "../../../../assets/icons";
import AnnounceImg from "../../../../assets/imgs/announcement1-img.jpg";
import AnnounceImgAr from "../../../../assets/imgs/announcement1-img-ar.jpg";

import "../announcement.style.scss";

function IntroSection() {
    const { t, i18n } = useTranslation();
    return (
        <Row className="intro-section">
            <Col span={24} className="published-date-container">
                <Avatar shape="circle" className="clockIcon"
                    src={<Clock style={{ height: "100%", width: "100%" }} />}
                />
                <span className="published-date">
                    {t("Published on Wed 12 Jun 2024")}
                </span>
            </Col>
            <Typography.Title level={1} className="title">
                {t("Thrivve Brand Announcement")}
            </Typography.Title>
            <Image
                src={i18n.language === "ar" ? AnnounceImgAr : AnnounceImg}
                alt="announcement" width={"100%"}
                preview={false} className="intro-image"
            />
            <Typography.Title level={3} className="subtitle">
                {t("We are thrilled to announce that WeDeliver is now")}
                <span className="highlighted"> {t("thrivve")} {i18n.language === "ar" ? "!" : ""} </span>
            </Typography.Title>
        </Row>
    );
}

export default IntroSection;