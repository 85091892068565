import { useTranslation } from "react-i18next";
import { CarIcon, CoinsIcon, InstantPaymentIcon } from "../../../assets/icons";
import { FadeUpFramer } from "../../../shared/components/custom-reveal-animations/custom-reveal-animations.ui";

const PotentialItemsSection = () => {
    const { t } = useTranslation();

    const items = [
        { icon: <CarIcon color="#451a6b" />, title: "bestValue", subtitle: "bestValueDetails" },
        { icon: <InstantPaymentIcon color="#451a6b" />, title: "flexibleTerms", subtitle: "flexibleTermsDetails" },
        { icon: <CoinsIcon color="#451a6b" />, title: "hassleFree", subtitle: "hassleFreeDetails" },
    ];

    return (
        <section className="potential-items-section">
            <div className="screen-wrapper">
                {items.map((item, index) => (
                    <FadeUpFramer key={index}>
                        <div className="item">
                            {item.icon}
                            <h5 className="title">{t(item.title)}</h5>
                            <p className="subtitle">{t(item.subtitle)}</p>
                        </div>
                    </FadeUpFramer>
                ))}
            </div>
        </section>
    );
};

export default PotentialItemsSection;
