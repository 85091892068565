import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
// import AnnounceHeader from "./partials/header";
import IntroSection from "./partials/intro-section";
import "./announcement.style.scss";
import WhiteNavBarSection from "../../../shared/components/white-nav-bar/white-nav-bar.ui";
import FooterSection from "../../../shared/components/footer-section/footer-section.ui";
import ParagraphsSection from "./partials/paragraphs-section";

function Announcement() {
    const { t } = useTranslation();
    return (
        <>
            <WhiteNavBarSection mobilePAgeTitle={t("Newsroom")} />
            <Row justify={"center"} align={"top"} className="announcement-main-container">
                <Col span={24}>
                    <IntroSection />
                    <ParagraphsSection />
                </Col>
            </Row>
            <FooterSection />
        </>
    );
}

export default Announcement;