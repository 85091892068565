import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import "./onboarding.style.css";
import HeaderSection from "./partials/header-section";
import CongratsSection from "./partials/congrats-section";
import StepSections from "./partials/steps-sections";
import FAQSection from "./partials/faq-section";
import HelpSection from "./partials/help-section";
// import AppSmartBanner from "./partials/smart-banner";


function OnBoardingPage() {
    const { i18n } = useTranslation();
    useEffect(() => {
        if (i18n.language !== "ar") {
            i18n.changeLanguage("ar");
        }
    }, [i18n]);
    return (
        <>
            {/* <Row>
                <Col xl={0} lg={0} md={0} sm={24} xs={24}>
                    <AppSmartBanner />
                </Col>
            </Row> */}
            <Row justify={"center"} align={"top"} className="onboarding-mainContainer">
                <Col xl={12} lg={12} md={18} sm={24} xs={24} className="onboarding-mainCol">
                    <HeaderSection />
                    <Row justify={"center"} align={"top"}>
                        <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                            <CongratsSection />
                            <StepSections />
                            <FAQSection />
                            <HelpSection />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}
export default OnBoardingPage;