import {
    LOADING_LOVS, LOVS_LOADED, LOADING_CITIES, CITIES_LOADED,
    UPDATE_CREATING_LEAD, UPDATE_LOADING_COUNTRIES, COUNTRIES_LOADED
} from './get-started.action'
const initialState:{[k: string]: any} ={
    lovsLoading:false,
    lovs: [],
    citiesLoading:false,
    cities:[],
    creating:false,
    countries:[],
    loadingCountries:false
}
const GetStartedReducer = (state = initialState, action: any) => {
    let newState = state;
    switch (action.type) {
        case LOADING_LOVS:
            newState = {...state, lovsLoading: action.payload};
            break;
        case LOVS_LOADED:
            newState = {...state,  lovsLoading:false, lovs:action.payload};
        break;
        case LOADING_CITIES:
            newState = {...state, citiesLoading:action.payload};
            break;
        case CITIES_LOADED:
            newState = {...state, citiesLoading:false, cities:action.payload};
            break;
        case UPDATE_CREATING_LEAD:
            newState = {...state, creating:action.payload};
            break;
        case COUNTRIES_LOADED:
            newState = {...state,  loadingCountries:false, countries:action.payload};
            break;
        case UPDATE_LOADING_COUNTRIES:
            newState = {...state,  loadingCountries:action.payload};
            break;
    }
    return newState
};
export default GetStartedReducer;